import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { Aside, Content } from './overview/style';
import Heading from '../../../components/heading/heading';
import { Badge, FaqCategoryBox, ListGroup, PricingCard } from '../../pages/style.js';
import { Cards } from '../../../components/cards/frame/cards-frame.js';
import { List } from '../../../components/pricing/pricing.js';
import { Button } from '../../../components/buttons/buttons.js';

const handleChange = (e) => {
  e.preventDefault();
  e.target.closest('ul').querySelector('a.active').classList.remove('active');
  e.target.classList.add('active');
};

const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Row>
        <Col xxl={8} xl={9} lg={12} md={8} xs={24}>
          <Aside>
            <div className="auth-side-content">
              <img src={require('../../../static/img/auth/topShape.png')} alt="" className="topShape" />
              <img src={require('../../../static/img/auth/bottomShape.png')} alt="" className="bottomShape" />
              <Content>
                <img style={{ width: '150px' }} src={require('../../../static/img/Logo_Dark.svg')} alt="" />
                <br />
                <Heading as="h1">
                  Turbo Develop - Web / Mobile Application
                </Heading>
                <FaqCategoryBox>
                  <Cards headless>
                    <Badge className="pricing-badge" type="dark">
                      What we do ? 
                    </Badge>
                    <ul>
                      <li>
                        <Link className="active primary" onClick={handleChange} to="#">
                          Web / Mobile Application Development
                        </Link>
                      </li>
                      <li>
                        <Link className="secondary" onClick={handleChange} to="#">
                          AI Modeling / Data Analytics
                        </Link>
                      </li>
                      <li>
                        <Link className="warning" onClick={handleChange} to="#">
                          Search Engine Optimization
                        </Link>
                      </li>
                    </ul>
                  </Cards>
                </FaqCategoryBox>
                <PricingCard style={{ marginBottom: 0 }}>
                    <Badge className="pricing-badge" type="primary">
                      Contact us
                    </Badge>
                    <ListGroup>
                      <List text="London, Ontario" />
                      <List text="billysaveearth@gmail.com" />
                      <List text="519-476-2069" />
                    </ListGroup>
                    {/*<Button size="small" type="primary">
                      Get Started
                    </Button>*/}
                  </PricingCard>
              </Content>
            </div>
          </Aside>
        </Col>

        <Col xxl={16} xl={15} lg={12} md={16} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
