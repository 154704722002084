import React from 'react';
import PropTypes from 'prop-types';
import { ListStyle } from './style';
import { alertModal } from '../../components/modals/antd-modals';
import { Badge } from '../../container/pages/style';
import FeatherIcon from 'feather-icons-react';

function List(props) {
  const {
    text,
    id,
    removeCleaner,
    type,
    removeSupervisor,
    removeSupportStaff,
    removeLocation,
    removeSupply,
    editSupply,
    editSupportStaff,
    allowEdit
  } = props;

  const onClick = () => {
    console.log('delete me');
    if (type === 'supply') {
      removeSupply(id);
    } else if (type === 'supportStaff') {
      removeSupportStaff(id);
    } else if (type === 'supervisor') {
      removeSupervisor(id);
    } else if (type === 'location') {
      removeLocation(id);
    } else {
      removeCleaner(id);
    }
  };

  const onEdit = () => {
    if (type === 'supply') {
      editSupply(id);
    } else if (type === 'location') {
      editLocation(id);
    } else {
      editSupportStaff(id);
    }
  };

  const showConfirm = () => {
    var itemName;
    if (type === 'supervisor') {
      itemName = 'Supervisor';
    } else if (type === 'location') {
      itemName = 'Location';
    } else {
      itemName = 'Cleaner';
    }

    alertModal.confirm({
      title: 'Do you want to remove the selected ' + itemName + ' from the current location?',
      content: 'Press the OK button, to confirm',
      onOk() {
        onClick();
      },
      onCancel() {},
    });
  };

  const showConfirm2 = () => {
    var itemName;
    if (type === 'supply') {
      itemName = 'Supply';
    } else {
      itemName = 'Support Staff';
    }

    alertModal.confirm({
      title: 'Do you want to remove the selected ' + itemName,
      content: 'Press the OK button, to confirm',
      onOk() {
        onClick();
      },
      onCancel() {},
    });
  };

  return (
    <ListStyle className="list-single">
      <span className="icon">
        <FeatherIcon size={16} icon="aperture" />
      </span>
      <span>{text}</span>
      {allowEdit &&
        <>
          {type !== 'supply' && type !== 'supportStaff' && (
            <span className="icon2">
              &emsp;&emsp;
              <FeatherIcon size={16} icon="trash-2" onClick={showConfirm} />
            </span>
          )}
          {(type === 'supply' || type === 'supportStaff') && (
            <span className="icon2">
              &emsp;&emsp;
              <FeatherIcon size={16} icon="trash-2" onClick={showConfirm2} />
            </span>
          )}
          {(type === 'supply' || type === 'supportStaff') && (
            <span className="icon3">
              &emsp;
              <FeatherIcon size={16} icon="edit" onClick={onEdit} />
            </span>
          )}
        </>
      }
    </ListStyle>
  );
}
List.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
};
export { List };

function List2(props) {
  const { text, id, type, map, notes, geo, email, date, phone, thumpsup, thumpsdown, error } = props;

  return (
    <ListStyle className="list-single">
      <span className="icon">
        {email && <FeatherIcon size={16} icon="mail" />}
        {date && <FeatherIcon size={16} icon="clock" />}
        {map && <FeatherIcon size={16} icon="compass" />}
        {phone && <FeatherIcon size={16} icon="phone" />}
        {notes && <FeatherIcon size={16} icon="printer" />}
        {geo && <FeatherIcon size={16} icon="sun" />}
        {thumpsup && <FeatherIcon size={16} icon="thumps-up" />}
        {thumpsdown && <FeatherIcon size={16} icon="thumps-down" />}
        {!map && !notes && !phone && !geo && !email && !date && !thumpsup && !thumpsdown && <FeatherIcon size={16} icon="coffee" />}
      </span>
      <span>{text}</span>
    </ListStyle>
  );
}
List2.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
};
export { List2 };

function List3(props) {
  const { text, id, type, map, notes, geo, email, date, subText } = props;

  return (
    <ListStyle className="list-single">
      <span className="icon">
        {email && <FeatherIcon size={16} icon="mail" />}
        {date && <FeatherIcon size={16} icon="clock" />}
        {map && <FeatherIcon size={16} icon="compass" />}
        {notes && <FeatherIcon size={16} icon="printer" />}
        {geo && <FeatherIcon size={16} icon="sun" />}
        {!map && !notes && !geo && !email && !date && <FeatherIcon size={16} icon="coffee" />}
      </span>
      {subText}&nbsp;<span>{text}</span>
    </ListStyle>
  );
}
List3.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  subText: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
};
export { List3 };

function List_Template(props) {
  const {
    text,
    id,
    locationId,
    type,
    map,
    notes,
    geo,
    square,
    squareNo,
    subText,
    ratingText,
    editChecklist,
    editInspectionlist,
    removeChecklist,
    removeInspectionlist,
    viewChecklist,
    viewInspectionlist,
    nonEdit,
    detail,
    allowEdit
  } = props;

  const onClick = () => {
    console.log('delete me');
    if (type === 'checklist') {
      removeChecklist(locationId, id);
    } else {
      removeInspectionlist(locationId, id);
    }
  };

  const onClickEditChecklist = () => {
    console.log('delete me');
    if (type === 'checklist') {
      editChecklist(locationId, id);
    } else {
      editInspectionlist(locationId, id);
    }
  };
  const onClickViewChecklist = () => {
    console.log('delete me');
    if (type === 'checklist') {
      viewChecklist(locationId, id);
    } else {
      viewInspectionlist(locationId, id);
    }
  };

  const showConfirm = () => {
    var itemName;
    if (type === 'checklist') {
      itemName = 'Checklist';
    } else {
      itemName = 'Inspection report';
    }
    alertModal.confirm({
      title: 'Do you want to remove the selected ' + itemName + ' from the current location?',
      content: 'Press the OK button, to confirm',
      onOk() {
        onClick();
      },
      onCancel() {},
    });
  };

  return (
    <>
      <ListStyle className="list-single">
        <span className="icon">
          {map && <FeatherIcon size={16} icon="compass" />}
          {notes && <FeatherIcon size={16} icon="printer" />}
          {geo && <FeatherIcon size={16} icon="sun" />}
          {square && <FeatherIcon size={16} icon="thumps-up" />}
          {squareNo && <FeatherIcon size={16} icon="thumps-down" />}
          {!map && !notes && !geo && !square && !squareNo && <FeatherIcon size={16} icon="coffee" />}
        </span>
        <span>{text}</span>

        {!nonEdit &&
          <>
            <span className="icon3">
              &emsp;
              <FeatherIcon size={16} icon="eye" onClick={onClickViewChecklist} />
            </span>
            {allowEdit &&
              <span className="icon3">
                &emsp;
                <FeatherIcon size={16} icon="edit" onClick={onClickEditChecklist} />
              </span>
            }
            {allowEdit &&
              <span className="icon2">
                &emsp;
                <FeatherIcon size={16} icon="trash-2" onClick={showConfirm} />
              </span>
            }
          </>
        }
      </ListStyle>
      
      {subText && 
        <ListStyle className="list-single">
          <ul><LoopingText detail={detail} subText={subText}></LoopingText></ul>
        </ListStyle>
      }

      {ratingText &&
        <Badge className="pricing-badge" type={ratingText == 'Exceed' ? 'success' : ratingText == 'Met' ? 'info' : ratingText == 'Improve' ? 'warning' : 'danger'}>
          {ratingText}
        </Badge>
      }
    </>
  );
}
List_Template.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
};
export { List_Template };

function LoopingText(props) {
  const { subText, detail } = props;

  var teamsArray = [];
  if (detail) {
    teamsArray = subText?.map(function (team) {
      return team.title;
    });
  } else {
    teamsArray = subText?.map(function (team) {
      return team.label;
    });
  }

  return (
    <li style={{fontSize:'18px'}}>{teamsArray.join(" | ")}</li>
  );
} export { LoopingText };

function List_Schedule(props) {
  const { text, id, locationId, type, map, notes, geo, subText, subText2, editSchedule, removeSchedule, allowEdit } = props;

  const onClick = () => {
    console.log('delete me');
    removeSchedule(locationId, id);
  };

  const onClickEditSchedule = () => {
    console.log('edit me');
    editSchedule(locationId, id);
  };

  const showConfirm = () => {
    alertModal.confirm({
      title: 'Do you want to remove the selected Schedule from the current location?',
      content: 'Press the OK button, to confirm',
      onOk() {
        onClick();
      },
      onCancel() {},
    });
  };

  return (
    <>
      <ListStyle className="list-single">
        <span className="icon">
          {map && <FeatherIcon size={16} icon="compass" />}
          {notes && <FeatherIcon size={16} icon="printer" />}
          {geo && <FeatherIcon size={16} icon="sun" />}
          {!map && !notes && !geo && <FeatherIcon size={16} icon="coffee" />}
        </span>
        <span>{text}</span>

        {false && 
          <span className="icon3">
            &emsp;
            <FeatherIcon size={16} icon="edit" onClick={onClickEditSchedule} />
          </span>
        }
        {allowEdit &&
          <span className="icon2">
            &emsp;
            <FeatherIcon size={16} icon="trash-2" onClick={showConfirm} />
          </span>
        }
      </ListStyle>
      <ListStyle className="list-single">
        <span style={{fontSize:"14px"}}>{subText}, {subText2}</span>
      </ListStyle>
    </>
  );
}
List_Schedule.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
};
export { List_Schedule };

function List4(props) {
  const { text, thumbsUp } = props;

  return (
    <>
      {!thumbsUp && (
        <ListStyle className="list-single">
          <span className="icon2">
            <FeatherIcon size={16} icon="thumps-down" />
          </span>
          <span>{text}</span>
        </ListStyle>
      )}

      {thumbsUp && (
        <ListStyle className="list-single">
          <span className="icon">
            <FeatherIcon size={16} icon="thumps-up" />
          </span>
          <span>{text}</span>
        </ListStyle>
      )}
    </>
  );
}
List4.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  thumbsUp: PropTypes.bool,
};
export { List4 };
