import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Users = lazy(() => import('../../container/pages/Users'));
const AddUser = lazy(() => import('../../container/pages/AddUsers'));
const UserDetails = lazy(() => import('../../container/pages/UserDetails'));

const Team = lazy(() => import('../../container/pages/Team'));

function PagesRoute() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/usersInfo/:id`} component={UserDetails} />
      <Route path={`${path}/team`} component={Team} />
      <Route path={`${path}/grid/:roleType`} component={Users} />
    </Switch>
  );
}

export default PagesRoute;
