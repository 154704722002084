import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { NavTitle } from './style';
import versions from '../demoData/changelog.json';

const { SubMenu } = Menu;

function MenuItems({ darkMode, toggleCollapsed, topMenu, events }) {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const { onRtlChange, onLtrChange, modeChangeDark, modeChangeLight, modeChangeTopNav, modeChangeSideNav } = events;
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}`}>
              <FeatherIcon icon="activity" />
            </NavLink>
          )
        }
        key="changelog"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          Activity
          <span className="badge badge-primary menuItem">{versions[0].version}</span>
        </NavLink>
      </Menu.Item>
      <SubMenu key="project" icon={!topMenu && <FeatherIcon icon="target" />} title="Work Locations">
        <Menu.Item key="view">
          <NavLink onClick={toggleCollapsed} to={`${path}/project/view/grid`}>
            Work Location
          </NavLink>
        </Menu.Item>
        {/*TODO <Menu.Item key="ProjectCreate">
          <NavLink onClick={toggleCollapsed} to={`${path}/project/past/add`}>
            Work Location Activity
          </NavLink>
        </Menu.Item> */}
      </SubMenu>

      <SubMenu key="users" icon={!topMenu && <FeatherIcon icon="users" />} title="My Team">
        {/*TODO <Menu.Item key="team">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/grid/managers`}>
            Managers
          </NavLink>
        </Menu.Item>
        <Menu.Item key="user-grid">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/grid/leads`}>
            Leads
          </NavLink>
        </Menu.Item> */}
        <Menu.Item key="user-list">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/grid/developers`}>
            Devs
          </NavLink>
        </Menu.Item>
        <Menu.Item key="addUser">
          <NavLink disabled onClick={toggleCollapsed} to={`${path}/users/add-user/info`}>
            Add New Role
          </NavLink>
        </Menu.Item>
      </SubMenu>

      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/main/chat/private`}>
              <FeatherIcon icon="message-square" />
            </NavLink>
          )
        }
        key="chat"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/main/chat/private`}>
          Chat
        </NavLink>
      </Menu.Item>

      {/*TODO 
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}`}>
                <FeatherIcon icon="box" />
              </NavLink>
            )
          }
          key="template"
        >
          <NavLink disabled onClick={toggleCollapsed} to={`${path}/app/note/all`}>
            Work Docs
            <span className="badge badge-primary menuItem">{versions[0].version}</span>
          </NavLink>
        </Menu.Item> 
      */}
      
    </Menu>
  );
}

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
