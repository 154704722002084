import Cookies from 'js-cookie';
import actions from './actions';
import CONFIG_API from '../../config.json';
import axios from 'axios';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (values) => {
  return async (dispatch) => {
    
    try {
      dispatch(loginBegin());
      console.log('login $$$$$$$$', values);
      const response = await axios.post(CONFIG_API['BIGTEAM.API'] + 'validateAuth', values, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.errors) {
        Cookies.remove('user_id');
        Cookies.remove('secret_key');
        Cookies.remove('user_role');
        dispatch(loginErr(response.data.errors));
      } else {
        Cookies.set('logedIn', true);
        Cookies.set('user_id', response.data.id);
        Cookies.set('secret_key', response.data.secretKey);
        Cookies.set('user_role', response.data.role);
        dispatch(loginSuccess(true));
        callback();
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      Cookies.remove('logedIn');
      Cookies.remove('access_token');
      Cookies.remove('secret_key');
      Cookies.remove('user_id');

      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut };
